import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CaptureAction from '@store/actions/capture';
import Page from '@lib/components/v2/Page';
import { geoLocation, checkIfCameraEnabled } from '@lib/Utils';
import APIs from '@services/APIs';
import { localizedString } from '@languages';
import { CaptureNSWDigitalDriverLicenceScreen } from '@lib/pages/v2/CaptureNSWDigitalDriverLicenceScreen';
import { DOCUMENTS } from '@lib/constants/documents';

import {
  ENABLE_ONE_DOC_CONDITION,
  HIDE_FOOTER_UPLOAD_SPINNER,
  LOCATION,
  LOCATION_REQUIRED
} from '@spotMobileConfig';

import CaptureVisa from '@lib/components/v2/CaptureVisa';
import { BackOfCard } from '@lib/components/v2/Contents';
import { IdSelectionContent } from '../../components/Contents';
import { Error500 } from '../../errors';
import { EnableLocation, LocationSettings, Permission } from './Capture.error';

class Capture extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onGoBack: PropTypes.func,
    onExit: PropTypes.func,
    selectedDocumentList: PropTypes.array,
    flowType: PropTypes.string,
    onGeoLocation: PropTypes.func,
    onCaptured: PropTypes.func,
    setFrontIDParams: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.element = null;

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleCapture = this.handleCapture.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      progressBar: 0,
      isUploading: false,
      error: null,
      showCaptureVisa: false
    };
  }

  componentDidMount() {
    const { onGeoLocation, onExit } = this.props;

    this.checkCameraPermission();
    document.addEventListener('visibilitychange', this.checkCameraPermission);

    if (LOCATION)
      geoLocation()
        .then((geolocation) => onGeoLocation(geolocation))
        .catch(() => {
          if (!LOCATION_REQUIRED) {
            onGeoLocation(null);
            return;
          }
          const error = {
            component: EnableLocation,
            props: {
              buttons: [
                {
                  label: localizedString('exit'),
                  variant: 'outline',
                  onClick: () => onExit()
                },
                {
                  label: localizedString('enable'),
                  onClick: () => {
                    const error = {
                      component: LocationSettings,
                      props: {
                        buttons: [
                          {
                            label: localizedString('exit'),
                            variant: 'outline',
                            onClick: () => onExit()
                          },
                          {
                            label: localizedString('checkAgainLabel'),
                            onClick: () => {
                              geoLocation()
                                .then((geolocation) =>
                                  this.setState({
                                    geolocation,
                                    error: null
                                  })
                                )
                                .catch(() => null);
                            }
                          }
                        ]
                      }
                    };
                    this.setState({ error });
                  }
                }
              ]
            }
          };
          this.setState({ error });
        });
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.checkCameraPermission);
  }

  handleNextStep() {
    this.element.click();
  }

  /**
   * Go back to the previous step
   * @param {ClickEvent} e
   * @return {Void}
   */
  async handleGoBack(e) {
    e?.preventDefault?.();

    const { onGoBack } = this.props;

    const { selectedDocumentList } = this.props;

    const capturedDocumentList = selectedDocumentList.filter((doc) => {
      return doc.captured;
    });

    const lastCard =
      capturedDocumentList.length > 0
        ? capturedDocumentList[capturedDocumentList.length - 1]
        : selectedDocumentList.find((card) => card.backOfCard);

    if (lastCard) {
      this.setState(this.getInitialState());
    }
    onGoBack(lastCard);
  }

  /**
   * When user happy with photo.
   * @param {ChangeEvent} e
   * @param currentDoc
   */
  async handleCapture(e, currentDoc) {
    if (!e.target.files[0]) {
      return;
    }

    const imagefile = e.target.files[0];
    e.target.value = '';

    this.captureFile(imagefile, currentDoc);
  }

  /**
   * Go to the next step on Visa Upload
   * @return {Void}
   */
  handleSkip = () => {
    const { geolocation } = this.state;
    this.props.onNextStep({ geolocation });
    this.props.setFrontIDParams({});
    this.setState({
      showCaptureVisa: false
    });
  };

  /** When user uploads visa after Passport.
   * @param {ChangeEvent} e
   */

  handleCaptureVisa = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    const imagefile = e.target.files[0];
    e.target.value = '';

    this.uploadVisaImage(imagefile);
  };

  onClickedFooterTakePhoto(backOfCard) {
    const { isCameraEnabled } = this.state;
    this.checkCameraPermission();
    if (!isCameraEnabled) {
      const error = {
        component: Permission,
        props: {
          buttons: [
            {
              label: localizedString('back'),
              variant: 'transparent',
              onClick: () => this.setState({ error: null })
            },
            {
              label: localizedString('tryAgain'),
              onClick: () => this.setState({ error: null })
            }
          ]
        }
      };
      this.setState({ error });
      return;
    }
    if (backOfCard) {
      this.element.click();
    } else {
      this.handleNextStep();
    }
  }

  onClickedCaptureBackCard() {
    this.element.click();
  }

  checkCameraPermission = async () => {
    const isCameraEnabled = await checkIfCameraEnabled();
    this.setState({ isCameraEnabled });
  };

  uploadVisaImage(imagefile) {
    APIs.status('capturingId');
    const { setFrontIDParams, onNextStep, onGoBack } = this.props;
    const { geolocation } = this.state;
    const params = { idType: 'PASSPORT', frontFile: imagefile };

    this.setState({ isUploading: true, showCaptureVisa: false, progressBar: 0 });

    APIs.uploadVisa(params, {
      before: () => this.setState({ progressBar: 0 }),
      onProgress: (width) => {
        this.setState({ progressBar: width });
      }
    })
      .then(({ status, msg: error, skip = false }) => {
        if (status === 'error') {
          this.setState({
            error,
            isUploading: false
          });
          if (skip) {
            setFrontIDParams({});
            onNextStep({ geolocation });
          }
          return;
        }

        this.setState({
          isUploading: false
        });

        setFrontIDParams({});
        onNextStep({ geolocation });
      })
      .catch(() => {
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              setFrontIDParams({});
              onGoBack();
            }
          }
        };
        this.setState({ error, isUploading: false });
      });
  }

  captureFile(imagefile, currentDoc) {
    const { flowType, onCaptured, selectedDocumentList, onNextStep } = this.props;

    let params;
    if (currentDoc.backOfCard) {
      params = { backFile: imagefile, ...currentDoc.params };
    } else {
      params = { frontFile: imagefile, flowType, ocr: currentDoc.ocr };
    }

    onCaptured({ ...currentDoc, params });

    const pendingIDs = selectedDocumentList.filter((doc) => {
      return !doc.captured && currentDoc.type !== doc.type;
    });

    if (pendingIDs.length === 0) {
      onNextStep({ step: 1 });
    } else {
      this.setState(this.getInitialState());
    }
  }

  render() {
    const { progressBar, isUploading, error, showCaptureVisa } = this.state;
    const { component: Error, props: errorProps } = error || {};
    const { selectedDocumentList } = this.props;

    const needToCaptureFront = (doc) => !doc.captured && !doc.backOfCard;
    const needToCaptureBack = (doc) => !doc.captured && doc.backOfCard;
    const currentDoc =
      selectedDocumentList.find(needToCaptureFront) || selectedDocumentList.find(needToCaptureBack);

    const showCaptureDigitalIdScreen =
      currentDoc.type === DOCUMENTS.DIGITAL_DRIVER_LICENCE.documentType;

    const captureVisaProps = {
      onSkip: this.handleSkip,
      onUploadVisa: this.handleNextStep
    };

    let footerButtons = [];
    if (!Error) {
      footerButtons = [
        {
          label: localizedString('back'),
          variant: 'transparent',
          onClick: this.handleGoBack,
          dataTestId: 'docs-back'
        }
      ];
      if (isUploading && !HIDE_FOOTER_UPLOAD_SPINNER) {
        footerButtons.push({
          label:
            isUploading && progressBar < 100
              ? localizedString('uploading')
              : localizedString('loading'),
          variant: 'transparent',
          loading: true,
          dataTestId: 'docs-uploading'
        });
      } else {
        let captureLabel = localizedString('captureMyID');
        if (ENABLE_ONE_DOC_CONDITION) {
          captureLabel = 'Capture my I.D';
        }

        footerButtons.push({
          label: captureLabel,
          type: 'submit',
          onClick: () => this.onClickedFooterTakePhoto(currentDoc.backOfCard),
          dataTestId: 'docs-capture'
        });
      }
    }

    return (
      <div>
        <input
          type="file"
          name="image"
          accept="image/*"
          capture="environment"
          onChange={
            showCaptureVisa ? this.handleCaptureVisa : (e) => this.handleCapture(e, currentDoc)
          }
          ref={(ref) => {
            this.element = ref;
          }}
          style={{ opacity: 0, zIindex: 99 }}
          aria-hidden="true"
        />
        {Error && <Error {...errorProps} />}

        {showCaptureVisa && <CaptureVisa {...captureVisaProps} />}
        {showCaptureDigitalIdScreen && (
          <CaptureNSWDigitalDriverLicenceScreen
            onUploadDigitalImage={({ imageFile }) => this.captureFile(imageFile, currentDoc)}
            onCloseScreen={this.handleGoBack}
          />
        )}

        {!showCaptureVisa && !showCaptureDigitalIdScreen && !Error && currentDoc && (
          <Page buttons={footerButtons} forceFillViewPort>
            {currentDoc.backOfCard && (
              <BackOfCard
                idType={currentDoc.type}
                onReady={() => this.onClickedCaptureBackCard()}
              />
            )}
            {!currentDoc.backOfCard && (
              <IdSelectionContent selectedCard={currentDoc} progressBar={progressBar} />
            )}
          </Page>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Capture);

function mapStateToProps({ capture }) {
  return {
    frontParams: capture.frontParams
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data))
  };
}
